import React from 'react'
import './App.css' // Import your CSS file for component styles

function App () {
  return (
    <>
      <div class='columns is-vcentered' style={{ marginTop: '15vh' }}>
        <div class='column'></div>
        <div class='column is-4 has-text-centered'>
          <figure class='image'>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/The_Wounded_Angel_-_Hugo_Simberg.jpg/1280px-The_Wounded_Angel_-_Hugo_Simberg.jpg'
              alt='The Wounded Angel by Hugo Simberg'
            />
          </figure>
          <h1 class='painting-text is-size-6 has-text-right'>
            The Wounded Angel
            <br />
            Painting by Hugo Simberg (1903)
          </h1>
        </div>
        <div class='column'></div>
      </div>
    </>
  )
}

export default App
